import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import favicon from '../assets/favicon.png';

const query = graphql`
  {
    site {
      siteMetadata {
        author
        siteDesc: description
        image
        siteUrl
        siteTitle: title
        twitterUsername
      }
    }
  }
`;
const SEO = ({
  title,
  description,
  url,
  type,
  keywords,
  imageSeo,
}) => {
  const { site } = useStaticQuery(query);
  const {
    siteDesc,
    // siteTitle,
    siteUrl,
    image,
    twitterUsername,
  } = site.siteMetadata;
  return (
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <link rel="icon" type="image/png" href={favicon} />
      <title>{title}</title>
      <meta name="description" content={description || siteDesc} />
      <meta name="image" content={!imageSeo ? image : imageSeo} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={url} />

      <meta property="og:url" content={url} />
      <meta property="og:type" content={!type ? 'website' : type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content={!imageSeo ? siteUrl + image : imageSeo}
      />

      {/* twitter cards */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterUsername} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={siteDesc} />
      <meta
        name="twitter:image"
        content={!imageSeo ? siteUrl + image : imageSeo}
      />

      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-KTZ88DKHPP"
      />
      <script>
        {` window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-KTZ88DKHPP');`}
      </script>
    </Helmet>
  );
};

SEO.propTypes = {
  imageSeo: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
  keywords: PropTypes.string,
};

SEO.defaultProps = {
  imageSeo: '',
  title: '',
  description: '',
  url: '',
  type: '',
  keywords: '',
};
export default SEO;
