import React from 'react';
import {
  FaLinkedin,
  FaDribbble,
  FaBehance,
  FaInstagram,
  FaRegEnvelope,
} from 'react-icons/fa';
import '../css/social-links.scss';

const data = [
  {
    id: 1,
    icon: (
      <FaInstagram className="social-icon" aria-label="Instgram" />
    ),
    url: 'https://www.instagram.com/alexpolicastro/',
  },
  {
    id: 2,
    icon: (
      <FaLinkedin className="social-icon" aria-label="LinkedIn" />
    ),
    url: 'https://www.linkedin.com/in/alexpolicastro/',
  },
  {
    id: 3,
    icon: (
      <FaDribbble className="social-icon" aria-label="Dribbble" />
    ),
    url: 'https://dribbble.com/alexpolicastro',
  },
  {
    id: 4,
    icon: <FaBehance className="social-icon" aria-label="Behance" />,
    url: 'https://www.behance.net/alexpolicastro',
  },
  {
    id: 5,
    icon: (
      <FaRegEnvelope className="social-icon" aria-label="Email Me" />
    ),
    url: 'mailto:alex@policastro.design',
  },
];
const links = data.map(link => {
  return (
    <li key={link.id}>
      <a href={link.url} target="__blank" className="social-link">
        {link.icon}
      </a>
    </li>
  );
});

export default ({ styleClass }) => {
  return (
    <ul className={`social-links ${styleClass ? styleClass : ''}`}>
      {links}
    </ul>
  );
};
