import React from 'react';
import SocialLinks from '../constants/socialLinks';
import '../css/footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-wrapper">
          {/* <h2>AP</h2> */}
          <div className="title">Pixel Pusher & Code Junkie</div>

          <SocialLinks styleClass="footer-links" />

          <div className="made">
            Made with <a href="https://www.gatsbyjs.com/">Gatsby</a>
            {', '}
            <a href="https://strapi.io/">Strapi</a> &amp;{' '}
            <a href="https://www.netlify.com/">Netlify</a>
          </div>
          <div className="copyright">
            <b>Created by me &copy;{new Date().getFullYear()}</b>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
