import React from 'react';
// import logo from "../assets/logo.svg"
import { FaHamburger } from 'react-icons/fa';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import PageLinks from '../constants/links';

import '../css/navbar.scss';

const Navbar = ({ toggleSidebar }) => {
  return (
    <nav
      className="navbar"
      role="navigation"
      data-sal="fade"
      data-sal-duration="1000"
      data-sal-delay="100"
      data-sal-easing="easeInOutSine"
    >
      <div className="nav-center">
        <div className="nav-header">
          {/* <img src={logo} alt="logo" /> */}
          <Link className="logo gradient-text" to="/">
            AP
          </Link>
          <button
            type="button"
            className="toggle-btn"
            onClick={toggleSidebar}
          >
            <FaHamburger />
          </button>
        </div>

        <PageLinks styleClass="nav-links" />
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  toggleSidebar: PropTypes.func,
};

Navbar.defaultProps = {
  toggleSidebar: () => {},
};

export default Navbar;
