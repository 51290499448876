import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FaTimes } from 'react-icons/fa';
import Links from '../constants/links';
import SocialLinks from '../constants/socialLinks';
import '../css/sidebar.scss';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <aside className={`sidebar ${isOpen ? 'show-sidebar' : ''} `}>
      <button
        type="button"
        className="close-btn"
        onClick={toggleSidebar}
        aria-label="Close Button"
      >
        <FaTimes />
      </button>
      <div className="side-container">
        <Link className="logo" to="/">
          AP
        </Link>
        <Links styleClass={`${isOpen ? 'sidebar-links' : ''}`} />
        <SocialLinks
          styleClass={`${isOpen ? 'sidebar-icons' : ''}`}
        />
      </div>
    </aside>
  );
};

Sidebar.propTypes = {
  isOpen: PropTypes.bool,
  toggleSidebar: PropTypes.func,
};

Sidebar.defaultProps = {
  isOpen: false,
  toggleSidebar: () => {},
};

export default Sidebar;
